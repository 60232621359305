import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Lesson from "../components/lesson/lesson"

const LessonDetail = ({ data, pageContext: { parent, language } }) => {
  return (
    <Layout parent={parent}>
      <HelmetDatoCms
        defer={false}
        seo={data.lesson.seoMetaTags}
        language={language}
      />
      <Lesson lesson={data.lesson} />
    </Layout>
  )
}

export const query = graphql`
  query lessonById($id: String) {
    lesson: datoCmsLesson(id: { eq: $id }) {
      ...Lesson
    }
  }
`

export default LessonDetail
