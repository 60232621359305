import React from "react"
import { graphql } from "gatsby"
const lesson = () => {
  return <div>lesson</div>
}

export default lesson

// import React from "react"
// import style from "./lesson.mod.scss"
// import {
//   Vimeo,
//   Block,
//   Banner,
//   Content,
//   Image,
//   Protected,
//   LiveStream
// } from "@components"
// import getWeek from "date-fns/getWeek"
// import { CommentSection } from "gatsby-theme-comments"

// const Lesson = ({ lesson, language }) => {
//   const {
//     title,
//     image,
//     comingSoonText,
//     id,
//     week,
//     mainContentNode,
//     vimeoId,
//     video,
//     featuredImage,
//     startDate,
//     secondaryContentNode
//   } = lesson

//   const liveStream = getWeek(new Date(startDate)) === getWeek(new Date())
//   return (
//     <div className={style.lesson}>
//       {!liveStream && !video && (
//         <Banner
//           image={featuredImage}
//           overlayText={comingSoonText}
//           overlayHeading="Coming Soon"
//         />
//       )}
//       {liveStream && vimeoId && !video && (
//         <LiveStream vimeoId={vimeoId} chatBannerText={comingSoonText} />
//       )}
//       {video && (
//         <div className={style.lesson__video}>
//           <Vimeo videoUrl={video} poster={featuredImage} />
//         </div>
//       )}
//       <div className={style.lesson__content}>
//         <Block guttersMobile padding="bottom" maxWidth="inner">
//           <h3 className={style.lesson__week}>{week}</h3>
//           <h1 className={style.lesson__title}>{title}</h1>
//           <Content html={mainContentNode.childMarkdownRemark.html} />
//         </Block>
//         {(!video || liveStream) &&
//           (secondaryContentNode?.childMarkdownRemark?.html || image) && (
//             <Block background="primary-tint" padding="both">
//               <Block guttersMobile maxWidth="inner">
//                 {secondaryContentNode?.childMarkdownRemark?.html && (
//                   <Content
//                     html={secondaryContentNode.childMarkdownRemark.html}
//                   />
//                 )}
//                 {image && (
//                   <div className={style.lesson__image}>
//                     <Image image={image} />
//                   </div>
//                 )}
//               </Block>
//             </Block>
//           )}
//         {video && (
//           <Block guttersMobile maxWidth="inner" padding="both">
//             <Protected fullHeight={false}>
//               <CommentSection id={id} />
//             </Protected>
//           </Block>
//         )}
//       </div>
//     </div>
//   )
// }

// export default Lesson

export const query = graphql`
  fragment Lesson on DatoCmsLesson {
    title
    course {
      id
      slug
    }
    comingSoonText
    description
    id
    image {
      gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "540" })
    }
    bannerImage: image {
      gatsbyImageData(imgixParams: { fit: "crop", w: "1280", h: "640" })
    }
    week: lesson
    mainContentNode {
      childMarkdownRemark {
        id
        html
      }
    }
    vimeoId
    featuredImage {
      gatsbyImageData(imgixParams: { fit: "crop", w: "1280", h: "640" })
    }
    featuredImageCard: featuredImage {
      gatsbyImageData(imgixParams: { fit: "crop", w: "730", h: "525" })
    }
    video {
      url
      providerUid
    }
    startDate
    slug
    path
    secondaryContentNode {
      childMarkdownRemark {
        id
        html
      }
    }
  }
`
